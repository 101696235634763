import '../firebase-options.js';
import { Authentication } from "../app/core/authentication/authentication";
// import { env } from "../../env";

export const LOAD_ENV = async () => {
	// if(!this.isBrowser) return;

	const resp = await fetch('assets/config/config.json')
		.then((response) => response.json());

	// this.apiUrl = resp.apiUrl;
	// this.wsApi = resp.wsApi;
	const env2 = {
		api: resp.apiUrl,
		wsApi: resp.wsApi,
	};
	Object.assign(environment, env2);
	// environment = {...environment, env2};
};
export const environment = {
    // @ts-ignore
    firebaseOptions: self?.firebaseOptions,
    appVersion: require('../../package.json').version,
    production: false,
    sandbox: true,
    staging: false,
    development: false,
	api: "",
	wsApi: "",
	accountApi: 'https://auth.joinin.com.br',
	accountApi2: 'https://auth2.joinin.com.br',
    headers: Authentication.getHeaders,
    local: 'sandbox'
};
